import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { EmailInput } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { useApplication } from "@ryerson/frontend.application";
import { SubscribeContent } from "./Content";
import {
	AnalyticsUtilities,
	GoogleAnalyticsEventName,
	GoogleAnalyticsEventActionNames,
} from "@ryerson/frontend.common";

export type SubscribeContentProps = {
	title: string;
	description: string;
	actionLabel: string;
	inputPlaceholder: string;
	successMessage: string;
	imageUrl: string;
	errorMessage?: string;
};

export type SubscribeProps = {
	parent: string;
	content?: SubscribeContentProps;
	slim?: boolean;
	parentCategory?: string;
};

const StayTunedBg = styled.div`
	position: absolute;
	right: 0;
	margin-top: -85px;
	height: 353px;
	width: 50%;
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: right center;
	opacity: 1;
`;

const StayTunedTitle = styled.div`
	width: 480px;
	display: inline-block;
	vertical-align: top;
	height: 183px;
	padding-right: 120px;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
`;

const StayTunedInput = styled.div`
	width: calc(100% - 680px);
	display: inline-block;
	vertical-align: top;
	height: 80px;
	overflow: visible;
	position: relative;
	z-index: 1;
	box-sizing: border-box;
`;

const StayTunedButton = styled.div`
	width: 160px;
	display: inline-block;
	margin-left: 40px;
	vertical-align: top;
	height: 80px;
	margin-top: 103px;
	position: relative;
	z-index: 1;
	box-sizing: border-box;
`;

const SuccessMessge = styled.div`
	display: inline-block;
`;

const MobileStayTuned = styled.div`
	width: 100%;
	display: block;
	height: auto;
	min-height: 290px;
	padding: 30px 20px 40px 20px;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: right center;
	margin-top: 30px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.tertiary.background};
		`;
	}}
`;

const SlimStayTuned = styled.div`
	display: block;
	width: 100%;
	height: auto;
	min-height: 297px;
	padding: 28px 24px 36px 24px;
	box-sizing: border-box;
	border-radius: 2px;
	background-size: auto 100%;
	background-position: right center;
	background-repeat: no-repeat;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.tertiary.background};
		`;
	}}
`;

const SlimStayTunedButton = styled.div`
	display: block;
	width: 100%;
	margin-top: 10px;
	box-sizing: border-box;
`;

const StayTunedComponent: React.FC<SubscribeProps> = ({
	content,
	slim,
	parent,
	parentCategory,
}) => {
	const { theme } = useTheme();
	const { sendGAEvent } = AnalyticsUtilities;
	const {
		localization: { language },
		axiosInstance,
	} = useApplication();

	if (!content) content = SubscribeContent[language];

	const [email, setEmail] = React.useState<string>("");
	const [emailSent, setEmailSent] = React.useState<boolean>(false);
	const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);

	const submitEmail = () => {
		let apiBody: object = {
			email: email,
		};

		sendGAEvent(GoogleAnalyticsEventName.STAY_TUNED, {
			action: GoogleAnalyticsEventActionNames.SUBMIT,
			value: `${parent}${parentCategory ? `_${parentCategory}` : ""}`,
		});

		axiosInstance
			.post("/api/common/v1/public/stay-tuned", apiBody)
			.then((response) => {
				if (response.status === 200) {
					setEmailSent(true);
					setEmail("");
				} else {
					setShowErrorMessage(true);
					setInterval(() => setShowErrorMessage(false), 8000);
				}
			})
			.catch(() => {});
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				{slim ? (
					<>
						<SlimStayTuned
							theme={theme}
							css={css`
								background-image: url(${content.imageUrl});
							`}
						>
							<Typography
								variant="h3"
								type="tertiary"
								css={css`
									margin-top: 0px;
									letter-space: -2px;
									margin-bottom: 12px;
								`}
							>
								{content.title}
							</Typography>
							<Typography
								variant="div"
								css={css`
									margin-bottom: ${showErrorMessage ? "10px" : "40px"};
								`}
								weight="bold"
								type="tertiary"
								size="md"
							>
								{content.description}
							</Typography>
							{emailSent ? (
								<SuccessMessge>
									<Typography
										css={css`
											margin-bottom: 0px;
											letter-spacing: -1px;
										`}
										type="tertiary"
										size="lg"
										variant="div"
										color={theme.colors.tertiary.header}
									>
										{content.successMessage}
									</Typography>
								</SuccessMessge>
							) : (
								<>
									{showErrorMessage && (
										<Typography
											variant="p"
											color={theme.colors.tertiary.error}
											css={css`
												margin-top: 0;
											`}
										>
											{content.errorMessage}
										</Typography>
									)}
									<EmailInput
										placeholder={content.inputPlaceholder}
										size="md"
										language={language}
										label=""
										onChange={(e) => {
											setEmail(e.target.value);
										}}
									/>
									<SlimStayTunedButton>
										<Button
											size="lg"
											fullwidth={true}
											type="primary"
											label={content.actionLabel}
											onClick={() => {
												submitEmail();
											}}
										/>
									</SlimStayTunedButton>
								</>
							)}
						</SlimStayTuned>
					</>
				) : (
					<>
						<ContentSection type="tertiary" vPadding="85px">
							<StayTunedBg
								theme={theme}
								css={css`
									background-image: url(${content.imageUrl});
								`}
							/>
							<StayTunedTitle>
								<Typography
									variant="h1"
									css={css`
										margin-top: 0px;
										letter-spacing: -2px;
										margin-bottom: ${language === "en" ? "40px" : "10px"};
									`}
									type="tertiary"
								>
									{content.title}
								</Typography>
								<Typography
									css={css`
										margin-bottom: 0px;
										letter-spacing: -1px;
									`}
									type="tertiary"
									variant="h4"
									color={theme.colors.tertiary.text}
								>
									{content.description}
								</Typography>
							</StayTunedTitle>
							{emailSent ? (
								<SuccessMessge
									css={css`
										margin-top: ${language === "en" ? "95px" : "120px"};
										max-width: 600px;
									`}
								>
									<Typography
										css={css`
											margin-bottom: 0px;
											letter-spacing: -1px;
										`}
										type="tertiary"
										variant="h4"
										color={theme.colors.tertiary.header}
									>
										{content.successMessage}
									</Typography>
								</SuccessMessge>
							) : (
								<>
									<StayTunedInput
										css={css`
											margin-top: ${showErrorMessage ? "74px" : "103px"};
										`}
									>
										{showErrorMessage && (
											<Typography
												variant="p"
												color={theme.colors.tertiary.error}
												css={css`
													margin: 0 0 10px 0;
												`}
											>
												{content.errorMessage}
											</Typography>
										)}

										<EmailInput
											placeholder={content.inputPlaceholder}
											size="xl"
											label=""
											language={language}
											onChange={(e) => {
												setEmail(e.target.value);
											}}
										/>
									</StayTunedInput>
									<StayTunedButton>
										<Button
											size="xl"
											type="primary"
											label={content.actionLabel}
											onClick={() => {
												submitEmail();
											}}
										/>
									</StayTunedButton>
								</>
							)}
						</ContentSection>
					</>
				)}
			</Media>
			<Media lessThan="lg">
				<ContentSection vPadding="0" hPadding="0">
					<MobileStayTuned
						theme={theme}
						css={css`
							background-image: url(${content.imageUrl});
						`}
					>
						<Typography
							variant="h3"
							type="tertiary"
							css={css`
								letter-spacing: -1px;
								margin-top: 0px;
							`}
						>
							{content.title}
						</Typography>
						<Typography
							variant="p"
							css={css`
								margin-bottom: ${showErrorMessage ? "10px" : "30px"};
							`}
							type="tertiary"
							weight="bold"
						>
							{content.description}
						</Typography>
						{emailSent ? (
							<SuccessMessge>
								<Typography
									css={css`
										letter-spacing: -1px;
									`}
									type="tertiary"
									variant="h3"
									color={theme.colors.tertiary.header}
								>
									{content.successMessage}
								</Typography>
							</SuccessMessge>
						) : (
							<>
								{showErrorMessage && (
									<Typography variant="p" color={theme.colors.tertiary.error}>
										{content.errorMessage}
									</Typography>
								)}
								<EmailInput
									placeholder={content.inputPlaceholder}
									label=""
									language={language}
									css={css`
										margin-bottom: 10px;
									`}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
								<Button
									size="lg"
									type="primary"
									fullwidth={true}
									label={content.actionLabel}
									onClick={() => {
										submitEmail();
									}}
								/>
							</>
						)}
					</MobileStayTuned>
				</ContentSection>
			</Media>
		</>
	);
};

export default StayTunedComponent;
